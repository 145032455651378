import React from "react";
import { useDispatch, useSelector } from "react-redux";
import PayerDetails from "./GlobalFormComponents/PayerDetails";
import RecipientDetails from "./GlobalFormComponents/RecipientDetails";
import RecAccount from "./GlobalFormComponents/RecAccount";
import SubmitButton from "../SubmitButton/SubmitButton";
import UpdateButton from "../UpdateButton/UpdateButton";
import StateInfo from "./GlobalFormComponents/StateInfo";
import {
  indicatorTexts,
  k1099AmountDisplayTexts,
  stateInfoDisplayTexts,
} from "../../lib/languagePacks/en-us";
import AmountContainer from "./GlobalFormComponents/AmountContainer/AmountContainer";
import AmountHeading from "./GlobalFormComponents/AmountContainer/AmountHeading/AmountHeading";
import {
  AMOUNT_1,
  AMOUNT_2,
  AMOUNT_4,
  AMOUNT_5,
  AMOUNT_6,
  AMOUNT_7,
  AMOUNT_8,
  AMOUNT_9,
  AMOUNT_A,
  AMOUNT_B,
  AMOUNT_C,
  AMOUNT_D,
  AMOUNT_E,
  AMOUNT_F,
  AMOUNT_G,
  CORRECTION_INITIAL,
  DEFAULT_NON_FOREIGN_COUNTRY,
  EPF,
  ERROR_POPUP,
  K_1099,
  MERCHANT_CATEGORY_CODE,
  NUMBER_OF_PAYMENT_TRANSAC,
  PAGE_E_FILE,
  PAYMENT_CARD,
  PSE,
  PSE_NAME,
  PSE_NAME_CONT,
  PSE_PHONE,
  SUBMIT_ADD_ANOTHER,
  THIRD_PARTY_NETWORK,
} from "../../lib/constants";
import Amount from "./GlobalFormComponents/AmountContainer/AmountInput/Amount";
import FormDetail from "./GlobalFormComponents/InternalComponents/FormDetail";
import Select from "react-select";
import SelectStyle from "../../lib/ReactSelectTheme/reactSelectStyle";
import mccCodes from "../../lib/json/mcc-code-list.json";
import customFilter from "../../lib/form/utils/reactSelectFilter";
import { useEffect, useState } from "react";
import devLogInstance from "../../lib/loggerConfig";
import FilerPayment from "./FilerPayment/FilerPayment";
import PseDetails from "./PseDetails/PseDetails";
import { useNavigate } from "react-router-dom";
import handleAmountFormat from "../../lib/form/utils/handleAmountFormat";
import { NUMBER_ONLY_REGEX } from "../../lib/patterns";
import max from "../../lib/max";
import formatPhonenum from "../../lib/form/utils/formatPhone";
import formSubmissionHelper from "../../lib/FormSubmission/formSubmissionHelper";
import Indicator from "../Indicator";
import { isValidAmount } from "./Form1099-B";
import Middleware from "../../lib/utils/Middleware";
import handlePopup from "../../lib/popup/handlePopup";
import SessionStorage from "../../lib/LocalCaching/SessionStorage";
import CorrectionSubmissionType from "./GlobalFormComponents/CorrectionRelated/CorrectionSubmissionType";
import scrollToTop from "../../lib/utils/scrollToTop";
import { useRecTinUnknown } from "../../lib/customHooks";

// Function to format the merchant code json as required by react-select
const arrangeMerchantCodes = () => {
  const options = mccCodes.map((codeCategory) => {
    return {
      label: (
        <div style={{ display: "flex", gap: "0.5rem" }}>
          <span style={{ fontWeight: "bold" }}>{codeCategory.code}</span> -{" "}
          <span>{codeCategory.category}</span>
        </div>
      ),
      value: codeCategory.code,
    };
  });
  return [{ label: "None", value: null }, ...options];
};

const paymentTypeRequired = (paymentTypeval: any) => {
  if (paymentTypeval) return false;
  return true;
};

const merchantCodeRequired = (mcc: any) => {
  if (mcc && mcc !== "0000") return false;
  return true;
};

const numberOfPaymentTransacRequired = (value: any) => {
  if (value) return false;
  return true;
};

const isFilerTypeRequired = (currentFilerTypeVal: any) => {
  if (currentFilerTypeVal) return false;
  return true;
};

const validateRequiredFields = (
  paymentIndicator: any,
  filerTypeIndicator: any,
  merchantCode: any,
  numberOfPaymentTransaction: any,
  requiredFields: any,
  setRequiredFields: any
) => {
  let flag = 0;
  if (
    paymentTypeRequired(paymentIndicator) &&
    merchantCodeRequired(merchantCode) &&
    numberOfPaymentTransacRequired(numberOfPaymentTransaction) &&
    isFilerTypeRequired(filerTypeIndicator)
  ) {
    // Raise the required flag
    setRequiredFields({
      ...requiredFields,
      paymentTypeIndicator: true,
      merchantCode: true,
      numberOfPaymentTransac: true,
      filerType: true,
    });
    flag++;
  } else if (paymentTypeRequired(paymentIndicator)) {
    setRequiredFields({
      ...requiredFields,
      paymentTypeIndicator: true,
    });
    flag++;
  } else if (isFilerTypeRequired(filerTypeIndicator)) {
    setRequiredFields({
      ...requiredFields,
      filerType: true,
    });
    flag++;
  } else if (merchantCodeRequired(merchantCode)) {
    // Raise the required flag
    setRequiredFields({
      ...requiredFields,
      merchantCode: true,
    });
    flag++;
  } else if (numberOfPaymentTransacRequired(numberOfPaymentTransaction)) {
    // Raise the required flag
    setRequiredFields({
      ...requiredFields,
      numberOfPaymentTransac: true,
    });
    flag++;
  }

  return flag;
};

// Main
export default function Form1099K({
  formData,
  setFormData,
  formType,
  taxYear,
  isEditable,
  isCorrection,
  historyId,
}: any) {
  const style = useSelector((state: any) => state.themeChange);
  const dispatch = useDispatch();
  const [amount, setAmount] = useState({
    amount1: "",
    amount2: "",
    amount4: "",
    amount5: "",
    amount6: "",
    amount7: "",
    amount8: "",
    amount9: "",
    amountA: "",
    amountB: "",
    amountC: "",
    amountD: "",
    amountE: "",
    amountF: "",
    amountG: "",
  }); // for displaying on the input field
  const [miscFields, setMiscFields] = useState({
    merchant_category_code: "0000",
    number_of_payment_transactions: "",
    type_of_payment_indicator: 0,
    type_of_filer_indicator: 0,
    p_s_e_name: "",
    p_s_e_name_cont: "",
    p_s_e_phone_number: "",
  });
  const [mccList, setMccList] = useState<any[]>([]);
  const navigate = useNavigate();
  const [requiredFields, setRequiredFields] = useState({
    filerType: false,
    paymentTypeIndicator: false,
    merchantCode: false,
    numberOfPaymentTransac: false,
  });
  const correctionSubmissionType = useSelector((state: any) => state.correctionSubmissionType);

  const { recTinUnknown } = useRecTinUnknown();

  // devLogInstance.log(miscFields);

  useEffect(() => {
    setMccList(arrangeMerchantCodes());
    return () => {
      setMccList([]);
    };
  }, []);

  useEffect(() => {
    // Set the formatted amount values
    setAmount({
      amount1: formData.payment_amount1
        ? handleAmountFormat((formData.payment_amount1 / 100).toString())
        : "",
      amount2: formData.payment_amount2
        ? handleAmountFormat((formData.payment_amount2 / 100).toString())
        : "",
      amount4: formData.payment_amount4
        ? handleAmountFormat((formData.payment_amount4 / 100).toString())
        : "",
      amount5: formData.payment_amount5
        ? handleAmountFormat((formData.payment_amount5 / 100).toString())
        : "",
      amount6: formData.payment_amount6
        ? handleAmountFormat((formData.payment_amount6 / 100).toString())
        : "",
      amount7: formData.payment_amount7
        ? handleAmountFormat((formData.payment_amount7 / 100).toString())
        : "",
      amount8: formData.payment_amount8
        ? handleAmountFormat((formData.payment_amount8 / 100).toString())
        : "",
      amount9: formData.payment_amount9
        ? handleAmountFormat((formData.payment_amount9 / 100).toString())
        : "",
      amountA: formData.payment_amountA
        ? handleAmountFormat((formData.payment_amountA / 100).toString())
        : "",
      amountB: formData.payment_amountB
        ? handleAmountFormat((formData.payment_amountB / 100).toString())
        : "",
      amountC: formData.payment_amountC
        ? handleAmountFormat((formData.payment_amountC / 100).toString())
        : "",
      amountD: formData.payment_amountD
        ? handleAmountFormat((formData.payment_amountD / 100).toString())
        : "",
      amountE: formData.payment_amountE
        ? handleAmountFormat((formData.payment_amountE / 100).toString())
        : "",
      amountF: formData.payment_amountF
        ? handleAmountFormat((formData.payment_amountF / 100).toString())
        : "",
      amountG: formData.payment_amountG
        ? handleAmountFormat((formData.payment_amountG / 100).toString())
        : "",
    });

    // Set the formatted Misc field values
    setMiscFields({
      merchant_category_code: formData.merchant_category_code
        ? formData.merchant_category_code
        : "",
      number_of_payment_transactions: formData.number_of_payment_transactions
        ? formData.number_of_payment_transactions
        : "",
      type_of_payment_indicator: formData.type_of_payment_indicator
        ? formData.type_of_payment_indicator
        : null,
      type_of_filer_indicator: formData.type_of_filer_indicator
        ? formData.type_of_filer_indicator
        : null,
      p_s_e_name: formData.p_s_e_name ? formData.p_s_e_name : "",
      p_s_e_name_cont: formData.p_s_e_name_cont ? formData.p_s_e_name_cont : "",
      p_s_e_phone_number: formData.p_s_e_phone_number ? formData.p_s_e_phone_number : "",
    });

    return () => {
      setAmount({
        amount1: "",
        amount2: "",
        amount4: "",
        amount5: "",
        amount6: "",
        amount7: "",
        amount8: "",
        amount9: "",
        amountA: "",
        amountB: "",
        amountC: "",
        amountD: "",
        amountE: "",
        amountF: "",
        amountG: "",
      });
      setMiscFields({
        merchant_category_code: "",
        number_of_payment_transactions: "",
        type_of_payment_indicator: 0,
        type_of_filer_indicator: 0,
        p_s_e_name: "",
        p_s_e_name_cont: "",
        p_s_e_phone_number: "",
      });
    };
  }, [
    formData.payment_amount1,
    formData.payment_amount2,
    formData.payment_amount4,
    formData.payment_amount5,
    formData.payment_amount6,
    formData.payment_amount7,
    formData.payment_amount8,
    formData.payment_amount9,
    formData.payment_amountA,
    formData.payment_amountB,
    formData.payment_amountC,
    formData.payment_amountD,
    formData.payment_amountE,
    formData.payment_amountF,
    formData.payment_amountG,
    formData.merchant_category_code,
    formData.number_of_payment_transactions,
    formData.type_of_payment_indicator,
    formData.p_s_e_name,
    formData.p_s_e_name_cont,
    formData.p_s_e_phone_number,
    formData.type_of_filer_indicator,
  ]);

  // Handle input change on payment amount fields
  const handleChange = (e: any) => {
    const { id, value } = e.target;
    const formattedAmount = handleAmountFormat(value);
    switch (id) {
      case AMOUNT_1:
        isValidAmount(formattedAmount) &&
          setAmount({
            ...amount,
            amount1: formattedAmount,
          });
        break;
      case AMOUNT_2:
        isValidAmount(formattedAmount) &&
          setAmount({
            ...amount,
            amount2: formattedAmount,
          });
        break;
      case AMOUNT_4:
        isValidAmount(formattedAmount) &&
          setAmount({
            ...amount,
            amount4: formattedAmount,
          });
        break;
      case AMOUNT_5:
        isValidAmount(formattedAmount) &&
          setAmount({
            ...amount,
            amount5: formattedAmount,
          });
        break;
      case AMOUNT_6:
        isValidAmount(formattedAmount) &&
          setAmount({
            ...amount,
            amount6: formattedAmount,
          });
        break;
      case AMOUNT_7:
        isValidAmount(formattedAmount) &&
          setAmount({
            ...amount,
            amount7: formattedAmount,
          });
        break;
      case AMOUNT_8:
        isValidAmount(formattedAmount) &&
          setAmount({
            ...amount,
            amount8: formattedAmount,
          });
        break;
      case AMOUNT_9:
        isValidAmount(formattedAmount) &&
          setAmount({
            ...amount,
            amount9: formattedAmount,
          });
        break;
      case AMOUNT_A:
        isValidAmount(formattedAmount) &&
          setAmount({
            ...amount,
            amountA: formattedAmount,
          });
        break;
      case AMOUNT_B:
        isValidAmount(formattedAmount) &&
          setAmount({
            ...amount,
            amountB: formattedAmount,
          });
        break;
      case AMOUNT_C:
        isValidAmount(formattedAmount) &&
          setAmount({
            ...amount,
            amountC: formattedAmount,
          });
        break;
      case AMOUNT_D:
        isValidAmount(formattedAmount) &&
          setAmount({
            ...amount,
            amountD: formattedAmount,
          });
        break;
      case AMOUNT_E:
        isValidAmount(formattedAmount) &&
          setAmount({
            ...amount,
            amountE: formattedAmount,
          });
        break;
      case AMOUNT_F:
        isValidAmount(formattedAmount) &&
          setAmount({
            ...amount,
            amountF: formattedAmount,
          });
        break;
      case AMOUNT_G:
        isValidAmount(formattedAmount) &&
          setAmount({
            ...amount,
            amountG: formattedAmount,
          });
        break;

      default:
        break;
    }
  };

  // Handle change in any of the fields that are not amount related
  const handleMiscFieldChange = (e: any) => {
    const { id: fieldType, value } = e.target;
    switch (fieldType) {
      case NUMBER_OF_PAYMENT_TRANSAC:
        // Remove any pre-displayed error indicator
        setRequiredFields({
          ...requiredFields,
          numberOfPaymentTransac: false,
        });

        const numberRegex = new RegExp(NUMBER_ONLY_REGEX);
        const isValid = value.length > 0 ? numberRegex.test(value) : true;
        isValid &&
          value.length <= 13 &&
          setMiscFields({
            ...miscFields,
            number_of_payment_transactions: value,
          });
        break;

      case PAYMENT_CARD:
        // Remove any pre-displayed error indicator
        setRequiredFields({
          ...requiredFields,
          paymentTypeIndicator: false,
        });

        setMiscFields({
          ...miscFields,
          type_of_payment_indicator: !miscFields.type_of_payment_indicator ? 1 : 0,
        });
        break;

      case THIRD_PARTY_NETWORK:
        // Remove any pre-displayed error indicator
        setRequiredFields({
          ...requiredFields,
          paymentTypeIndicator: false,
        });

        setMiscFields({
          ...miscFields,
          type_of_payment_indicator: !miscFields.type_of_payment_indicator ? 2 : 0,
        });
        break;

      case PSE_NAME:
        const limitoflength = 30 - miscFields.p_s_e_name_cont.length;
        const currentValueLength = value.length;

        const isValidLength = max(currentValueLength, limitoflength) <= limitoflength;

        isValidLength &&
          setMiscFields({
            ...miscFields,
            p_s_e_name: value,
          });
        break;

      case PSE_NAME_CONT:
        const psenamecontlengthlimit = 30 - miscFields.p_s_e_name.length;
        const _currentValueLength = value.length;

        const isValidNameContLength =
          max(_currentValueLength, psenamecontlengthlimit) <= psenamecontlengthlimit;

        isValidNameContLength &&
          setMiscFields({
            ...miscFields,
            p_s_e_name_cont: value,
          });
        break;

      case PSE_PHONE:
        const formattedPhonenum = formatPhonenum(value);
        setMiscFields({
          ...miscFields,
          p_s_e_phone_number: formattedPhonenum ? formattedPhonenum : "",
        });
        break;

      case PSE:
        // Remove any pre-displayed error indicator
        setRequiredFields({
          ...requiredFields,
          filerType: false,
        });

        setMiscFields({
          ...miscFields,
          type_of_filer_indicator: !miscFields.type_of_filer_indicator ? 1 : 0,
        });
        break;

      case EPF:
        // Remove any pre-displayed error indicator
        setRequiredFields({
          ...requiredFields,
          filerType: false,
        });

        setMiscFields({
          ...miscFields,
          type_of_filer_indicator: !miscFields.type_of_filer_indicator ? 2 : 0,
        });
        break;

      default:
        break;
    }
  };

  // Handle mcc selection
  const handleMerchantCodeSelection = (selectedObj: any) => {
    if (selectedObj.value !== null) {
      // Remove any pre-displayed error indicator
      setRequiredFields({
        ...requiredFields,
        merchantCode: false,
      });

      const mcc = selectedObj.value;

      setMiscFields({
        ...miscFields,
        merchant_category_code: mcc,
      });
    } else {
      setMiscFields({
        ...miscFields,
        merchant_category_code: "0000",
      });
    }
  };

  // Get the value of merchant code select option
  const getMccSelectVal = () => {
    const selectedvalue = [
      {
        label:
          miscFields.merchant_category_code !== "0000" ? miscFields.merchant_category_code : "",
        value: miscFields.merchant_category_code,
      },
    ];
    return selectedvalue;
  };

  // Handle submit
  const handleFormSubmit = (e: any) => {
    e.preventDefault();
    const { id: submitType } = e.target;
    devLogInstance.log(`Clicked on ---> ${submitType}`);

    // Validate the form specific required fields
    const invalidationFlag = validateRequiredFields(
      miscFields.type_of_payment_indicator,
      miscFields.type_of_filer_indicator,
      miscFields.merchant_category_code,
      miscFields.number_of_payment_transactions,
      requiredFields,
      setRequiredFields
    );

    devLogInstance.log(`Invalidation Flag ---> ${invalidationFlag}`);

    // Submit the form only when there is no invalidation
    if (invalidationFlag <= 0) {
      let data = {
        ...formData,
        ...miscFields,
        p_s_e_phone_number: miscFields.p_s_e_phone_number
          .replace(/\(/g, "")
          .replace(/\)/g, "")
          .replace(/ /g, "")
          .replace(/-/g, ""),
        payer_country: !formData.payer_is_foreign_address
          ? DEFAULT_NON_FOREIGN_COUNTRY
          : formData.payer_country,
        recipient_country: !formData.recipient_is_foreign_address
          ? DEFAULT_NON_FOREIGN_COUNTRY
          : formData.recipient_country,
        form_type: formType,
        payment_amount1: amount.amount1 ? parseFloat(amount.amount1.replace(/,/gi, "")) : 0,
        payment_amount2: amount.amount2 ? parseFloat(amount.amount2.replace(/,/gi, "")) : 0,
        payment_amount4: amount.amount4 ? parseFloat(amount.amount4.replace(/,/gi, "")) : 0,
        payment_amount5: amount.amount5 ? parseFloat(amount.amount5.replace(/,/gi, "")) : 0,
        payment_amount6: amount.amount6 ? parseFloat(amount.amount6.replace(/,/gi, "")) : 0,
        payment_amount7: amount.amount7 ? parseFloat(amount.amount7.replace(/,/gi, "")) : 0,
        payment_amount8: amount.amount8 ? parseFloat(amount.amount8.replace(/,/gi, "")) : 0,
        payment_amount9: amount.amount9 ? parseFloat(amount.amount9.replace(/,/gi, "")) : 0,
        payment_amountA: amount.amountA ? parseFloat(amount.amountA.replace(/,/gi, "")) : 0,
        payment_amountB: amount.amountB ? parseFloat(amount.amountB.replace(/,/gi, "")) : 0,
        payment_amountC: amount.amountC ? parseFloat(amount.amountC.replace(/,/gi, "")) : 0,
        payment_amountD: amount.amountD ? parseFloat(amount.amountD.replace(/,/gi, "")) : 0,
        payment_amountE: amount.amountE ? parseFloat(amount.amountE.replace(/,/gi, "")) : 0,
        payment_amountF: amount.amountF ? parseFloat(amount.amountF.replace(/,/gi, "")) : 0,
        payment_amountG: amount.amountG ? parseFloat(amount.amountG.replace(/,/gi, "")) : 0,
      };
      devLogInstance.log(data);

      const paymentAmounts = [
        data.payment_amount1,
        data.payment_amount2,
        data.payment_amount4,
        data.payment_amount5,
        data.payment_amount6,
        data.payment_amount7,
        data.payment_amount8,
        data.payment_amount9,
        data.payment_amountA,
        data.payment_amountB,
        data.payment_amountC,
        data.payment_amountD,
        data.payment_amountE,
        data.payment_amountF,
        data.payment_amountG,
      ];
      const incomeAmounts = [
        data.payment_amount1,
        data.payment_amount2,
        data.payment_amount5,
        data.payment_amount6,
        data.payment_amount7,
        data.payment_amount8,
        data.payment_amount9,
        data.payment_amountA,
        data.payment_amountB,
        data.payment_amountC,
        data.payment_amountD,
        data.payment_amountE,
        data.payment_amountF,
        data.payment_amountG,
      ];

      // Correction filing middleware
      Middleware.fileCorrection(
        {
          isCorrection: isCorrection,
          correctionSubmissionType: correctionSubmissionType,
          correctionCode1Details: data,
          correctionCode2Details: data,
          formType: data.form_type,
        },
        (err, correctionCode) => {
          if (err) {
            console.error(err);
            handlePopup(err, ERROR_POPUP, dispatch);
            return;
          }

          if (correctionCode) {
            data = {
              ...data,
              file_submission_type: correctionSubmissionType,
              correction_type: correctionCode,
              payer_is_new_or_updated: false,
              history_id: historyId,
            };
            devLogInstance.log("Data before sumbitting correction");
            devLogInstance.log(data);
          }

          devLogInstance.log("Removing cached intial data...");
          SessionStorage.removeCacheItem(CORRECTION_INITIAL);
          devLogInstance.log("Cache removed!");

          formSubmissionHelper(
            data,
            paymentAmounts,
            data.payment_amount4,
            incomeAmounts,
            setFormData,
            setAmount,
            dispatch,
            submitType,
            navigate,
            PAGE_E_FILE,
            recTinUnknown
          ).then((res) => {
            if (submitType === SUBMIT_ADD_ANOTHER) {
              // Clear the local state values
              setMiscFields({
                merchant_category_code: "",
                number_of_payment_transactions: "",
                type_of_payment_indicator: 0,
                type_of_filer_indicator: 0,
                p_s_e_name: "",
                p_s_e_name_cont: "",
                p_s_e_phone_number: "",
              });
            }
          });
        }
      );
      // formSubmissionHelper(
      //   data,
      //   paymentAmounts,
      //   data.payment_amount4,
      //   incomeAmounts,
      //   setFormData,
      //   setAmount,
      //   dispatch,
      //   submitType,
      //   navigate,
      //   PAGE_E_FILE
      // ).then((res) => {
      //   if (submitType === SUBMIT_ADD_ANOTHER) {
      //     // Clear the local state values
      //     setMiscFields({
      //       merchant_category_code: "",
      //       number_of_payment_transactions: "",
      //       type_of_payment_indicator: 0,
      //       type_of_filer_indicator: 0,
      //       p_s_e_name: "",
      //       p_s_e_name_cont: "",
      //       p_s_e_phone_number: "",
      //     });
      //   }
      // });
    } else {
      scrollToTop();
    }
  };

  return (
    <>
      <form className="form-main-container">
        {isCorrection && <CorrectionSubmissionType />}
        <div
          className="form-wrapper"
          style={{
            border: `2px solid ${style.eFormBorderColor}`,
          }}
        >
          <div className="form-details-amount-wrapper">
            <div className="left-portion-details">
              <PayerDetails
                globalFormData={formData}
                setGlobalFormData={setFormData}
                // isForm1099S={true}
                formType={formType}
                isCorrection={isCorrection}
              />
              <FilerPayment
                handleChange={handleMiscFieldChange}
                paymentTypeIndicator={miscFields.type_of_payment_indicator}
                paymentTypeRequired={requiredFields.paymentTypeIndicator}
                filerTypeIndicator={miscFields.type_of_filer_indicator}
                filerTypeRequired={requiredFields.filerType}
              />
              <RecipientDetails
                globalFormData={formData}
                setGlobalFormData={setFormData}
                isForm1099S={true}
                formType={formType}
                isCorrection={isCorrection}
              />
              <PseDetails
                handleChange={handleMiscFieldChange}
                psename={miscFields.p_s_e_name}
                psenamecont={miscFields.p_s_e_name_cont}
                psephone={miscFields.p_s_e_phone_number}
              />
              <RecAccount
                globalFormData={formData}
                setGlobalFormData={setFormData}
                secondTinNoticeRequired={true}
              />
            </div>
            <div
              className="right-portion-details"
              style={{
                overflow: "hidden",
                borderBottom: `1px solid ${style.eFormBorderColor}`,
              }}
            >
              <div className="top-section">
                <div>
                  <AmountContainer
                    class_="misc-info" // Only one class can be passed
                    style={{
                      borderTop: `1px solid ${style.eFormBorderColor}`,
                    }}
                  >
                    <AmountHeading
                      heading={k1099AmountDisplayTexts.grossAmountOfPaymentCard}
                      amountNum="1a"
                    />
                    <Amount
                      type="text"
                      id={AMOUNT_1}
                      placeHolder="0.00"
                      class_="payer-details-input" // Only one class can be passed
                      value={amount.amount1}
                      onChangeHandler={handleChange}
                    />
                  </AmountContainer>
                </div>
                <FormDetail formType={formType} taxYear={taxYear} formName={K_1099} />
              </div>

              <div
                className="payment-amounts-container"
                style={{
                  borderRight: `1px solid ${style.eFormBorderColor}`,
                  borderBottom: `1px solid ${style.eFormBorderColor}`,
                }}
              >
                <AmountContainer
                  class_="misc-info" // Only one class can be passed
                  style={{
                    borderRight: `1px solid ${style.eFormBorderColor}`,
                  }}
                >
                  <AmountHeading
                    heading={k1099AmountDisplayTexts.cardNotPresentTransactions}
                    amountNum="1b"
                  />
                  <Amount
                    type="text"
                    id={AMOUNT_2}
                    placeHolder="0.00"
                    class_="payer-details-input" // Only one class can be passed
                    value={amount.amount2}
                    onChangeHandler={handleChange}
                  />
                </AmountContainer>
                <AmountContainer
                  class_="misc-info" // Only one class can be passed
                >
                  <AmountHeading
                    heading={k1099AmountDisplayTexts.merchantCategoryCode}
                    amountNum="2"
                  />
                  <Select
                    id={MERCHANT_CATEGORY_CODE}
                    theme={new SelectStyle(style).customTheme}
                    styles={new SelectStyle(style).merchantCodeSelectStyle}
                    options={mccList}
                    filterOption={customFilter}
                    onChange={handleMerchantCodeSelection}
                    value={getMccSelectVal()}
                    placeholder=""
                  />
                  {requiredFields.merchantCode && (
                    <Indicator
                      type={MERCHANT_CATEGORY_CODE}
                      message={indicatorTexts.merchantCodeRequired}
                    />
                  )}
                </AmountContainer>
              </div>

              <div
                className="payment-amounts-container"
                style={{
                  borderRight: `1px solid ${style.eFormBorderColor}`,
                  borderBottom: `1px solid ${style.eFormBorderColor}`,
                }}
              >
                <AmountContainer
                  class_="misc-info" // Only one class can be passed
                  style={{
                    borderRight: `1px solid ${style.eFormBorderColor}`,
                  }}
                >
                  <AmountHeading
                    heading={k1099AmountDisplayTexts.numberOfPaymentTransac}
                    amountNum="3"
                  />
                  <input
                    id={NUMBER_OF_PAYMENT_TRANSAC}
                    type="text"
                    style={{ padding: "0.5rem 1rem" }}
                    onChange={handleMiscFieldChange}
                    value={miscFields.number_of_payment_transactions}
                  />
                  {requiredFields.numberOfPaymentTransac && (
                    <Indicator
                      type={NUMBER_OF_PAYMENT_TRANSAC}
                      message={indicatorTexts.numberOfPaymentTransacRequired}
                    />
                  )}
                </AmountContainer>
                <AmountContainer
                  class_="misc-info" // Only one class can be passed
                >
                  <AmountHeading heading={k1099AmountDisplayTexts.taxWithheld} amountNum="4" />
                  <Amount
                    type="text"
                    id={AMOUNT_4}
                    placeHolder="0.00"
                    class_="payer-details-input" // Only one class can be passed
                    value={amount.amount4}
                    onChangeHandler={handleChange}
                  />
                </AmountContainer>
              </div>

              <div
                className="payment-amounts-container"
                style={{
                  borderRight: `1px solid ${style.eFormBorderColor}`,
                  borderBottom: `1px solid ${style.eFormBorderColor}`,
                }}
              >
                <AmountContainer
                  class_="misc-info" // Only one class can be passed
                  style={{
                    borderRight: `1px solid ${style.eFormBorderColor}`,
                  }}
                >
                  <AmountHeading heading={k1099AmountDisplayTexts.janPayment} amountNum="5a" />
                  <Amount
                    type="text"
                    id={AMOUNT_5}
                    placeHolder="0.00"
                    class_="payer-details-input" // Only one class can be passed
                    value={amount.amount5}
                    onChangeHandler={handleChange}
                  />
                </AmountContainer>
                <AmountContainer
                  class_="misc-info" // Only one class can be passed
                >
                  <AmountHeading heading={k1099AmountDisplayTexts.febPayment} amountNum="5b" />
                  <Amount
                    type="text"
                    id={AMOUNT_6}
                    placeHolder="0.00"
                    class_="payer-details-input" // Only one class can be passed
                    value={amount.amount6}
                    onChangeHandler={handleChange}
                  />
                </AmountContainer>
              </div>

              <div
                className="payment-amounts-container"
                style={{
                  borderRight: `1px solid ${style.eFormBorderColor}`,
                  borderBottom: `1px solid ${style.eFormBorderColor}`,
                }}
              >
                <AmountContainer
                  class_="misc-info" // Only one class can be passed
                  style={{
                    borderRight: `1px solid ${style.eFormBorderColor}`,
                  }}
                >
                  <AmountHeading heading={k1099AmountDisplayTexts.marPayment} amountNum="5c" />
                  <Amount
                    type="text"
                    id={AMOUNT_7}
                    placeHolder="0.00"
                    class_="payer-details-input" // Only one class can be passed
                    value={amount.amount7}
                    onChangeHandler={handleChange}
                  />
                </AmountContainer>
                <AmountContainer
                  class_="misc-info" // Only one class can be passed
                >
                  <AmountHeading heading={k1099AmountDisplayTexts.aprPayment} amountNum="5d" />
                  <Amount
                    type="text"
                    id={AMOUNT_8}
                    placeHolder="0.00"
                    class_="payer-details-input" // Only one class can be passed
                    value={amount.amount8}
                    onChangeHandler={handleChange}
                  />
                </AmountContainer>
              </div>

              <div
                className="payment-amounts-container"
                style={{
                  borderRight: `1px solid ${style.eFormBorderColor}`,
                  borderBottom: `1px solid ${style.eFormBorderColor}`,
                }}
              >
                <AmountContainer
                  class_="misc-info" // Only one class can be passed
                  style={{
                    borderRight: `1px solid ${style.eFormBorderColor}`,
                  }}
                >
                  <AmountHeading heading={k1099AmountDisplayTexts.mayPayment} amountNum="5e" />
                  <Amount
                    type="text"
                    id={AMOUNT_9}
                    placeHolder="0.00"
                    class_="payer-details-input" // Only one class can be passed
                    value={amount.amount9}
                    onChangeHandler={handleChange}
                  />
                </AmountContainer>
                <AmountContainer
                  class_="misc-info" // Only one class can be passed
                >
                  <AmountHeading heading={k1099AmountDisplayTexts.junPayment} amountNum="5f" />
                  <Amount
                    type="text"
                    id={AMOUNT_A}
                    placeHolder="0.00"
                    class_="payer-details-input" // Only one class can be passed
                    value={amount.amountA}
                    onChangeHandler={handleChange}
                  />
                </AmountContainer>
              </div>

              <div
                className="payment-amounts-container"
                style={{
                  borderRight: `1px solid ${style.eFormBorderColor}`,
                  borderBottom: `1px solid ${style.eFormBorderColor}`,
                }}
              >
                <AmountContainer
                  class_="misc-info" // Only one class can be passed
                  style={{
                    borderRight: `1px solid ${style.eFormBorderColor}`,
                  }}
                >
                  <AmountHeading heading={k1099AmountDisplayTexts.julPayment} amountNum="5g" />
                  <Amount
                    type="text"
                    id={AMOUNT_B}
                    placeHolder="0.00"
                    class_="payer-details-input" // Only one class can be passed
                    value={amount.amountB}
                    onChangeHandler={handleChange}
                  />
                </AmountContainer>
                <AmountContainer
                  class_="misc-info" // Only one class can be passed
                >
                  <AmountHeading heading={k1099AmountDisplayTexts.augPayment} amountNum="5h" />
                  <Amount
                    type="text"
                    id={AMOUNT_C}
                    placeHolder="0.00"
                    class_="payer-details-input" // Only one class can be passed
                    value={amount.amountC}
                    onChangeHandler={handleChange}
                  />
                </AmountContainer>
              </div>

              <div
                className="payment-amounts-container"
                style={{
                  borderRight: `1px solid ${style.eFormBorderColor}`,
                  borderBottom: `1px solid ${style.eFormBorderColor}`,
                }}
              >
                <AmountContainer
                  class_="misc-info" // Only one class can be passed
                  style={{
                    borderRight: `1px solid ${style.eFormBorderColor}`,
                  }}
                >
                  <AmountHeading heading={k1099AmountDisplayTexts.sepPayment} amountNum="5i" />
                  <Amount
                    type="text"
                    id={AMOUNT_D}
                    placeHolder="0.00"
                    class_="payer-details-input" // Only one class can be passed
                    value={amount.amountD}
                    onChangeHandler={handleChange}
                  />
                </AmountContainer>
                <AmountContainer
                  class_="misc-info" // Only one class can be passed
                >
                  <AmountHeading heading={k1099AmountDisplayTexts.octPayment} amountNum="5j" />
                  <Amount
                    type="text"
                    id={AMOUNT_E}
                    placeHolder="0.00"
                    class_="payer-details-input" // Only one class can be passed
                    value={amount.amountE}
                    onChangeHandler={handleChange}
                  />
                </AmountContainer>
              </div>

              <div
                className="payment-amounts-container"
                style={{
                  borderRight: `1px solid ${style.eFormBorderColor}`,
                  borderBottom: `1px solid ${style.eFormBorderColor}`,
                }}
              >
                <AmountContainer
                  class_="misc-info" // Only one class can be passed
                  style={{
                    borderRight: `1px solid ${style.eFormBorderColor}`,
                  }}
                >
                  <AmountHeading heading={k1099AmountDisplayTexts.novPayment} amountNum="5k" />
                  <Amount
                    type="text"
                    id={AMOUNT_F}
                    placeHolder="0.00"
                    class_="payer-details-input" // Only one class can be passed
                    value={amount.amountF}
                    onChangeHandler={handleChange}
                  />
                </AmountContainer>
                <AmountContainer
                  class_="misc-info" // Only one class can be passed
                >
                  <AmountHeading heading={k1099AmountDisplayTexts.decPayment} amountNum="5l" />
                  <Amount
                    type="text"
                    id={AMOUNT_G}
                    placeHolder="0.00"
                    class_="payer-details-input" // Only one class can be passed
                    value={amount.amountG}
                    onChangeHandler={handleChange}
                  />
                </AmountContainer>
              </div>

              <div
                style={{
                  width: "100%",
                  height: "100%",
                  borderRight: `1px solid ${style.eFormBorderColor}`,
                }}
              />
            </div>
          </div>
        </div>
        <StateInfo
          state1Heading={stateInfoDisplayTexts.k1099.state1.heading}
          state2Heading={stateInfoDisplayTexts.k1099.state2.heading}
          taxWithheldLabel={stateInfoDisplayTexts.k1099.stateTaxWithheldLabel}
          stateVolLabel={stateInfoDisplayTexts.k1099.stateLabel}
          stateNoLabel={stateInfoDisplayTexts.k1099.stateNoLabel}
          stateIncomeLabel={""}
          globalFormData={formData}
          setGlobalFormData={setFormData}
          formType={formType}
          isCorrection={isCorrection}
        />
      </form>
      {!isEditable ? (
        <SubmitButton handleSubmit={handleFormSubmit} isCorrection={isCorrection} />
      ) : (
        <UpdateButton handleUpdate={handleFormSubmit} />
      )}
    </>
  );
}
