import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useRecTinUnknown } from "../../lib/customHooks";
import handleAmountFormat from "../../lib/form/utils/handleAmountFormat";
import PayerDetails from "./GlobalFormComponents/PayerDetails";
import RecipientDetails from "./GlobalFormComponents/RecipientDetails";
import RecAccount from "./GlobalFormComponents/RecAccount";
import FormDetail from "./GlobalFormComponents/InternalComponents/FormDetail";
import {
  AMOUNT_1,
  AMOUNT_2,
  AMOUNT_4,
  ARCHER_MSA_INDICATOR,
  CORRECTION_INITIAL,
  DEFAULT_NON_FOREIGN_COUNTRY,
  ERROR_POPUP,
  HSA_INDICATOR,
  MA_MSA_INDICATOR,
  PAGE_E_FILE,
  SA_1099,
  SUBMIT_ADD_ANOTHER,
} from "../../lib/constants";
import SubmitButton from "../SubmitButton/SubmitButton";
import UpdateButton from "../UpdateButton/UpdateButton";
import AmountContainer from "./GlobalFormComponents/AmountContainer/AmountContainer";
import AmountHeading from "./GlobalFormComponents/AmountContainer/AmountHeading/AmountHeading";
import Amount from "./GlobalFormComponents/AmountContainer/AmountInput/Amount";
import { isValidAmount } from "./Form1099-B";
import devLogInstance from "../../lib/loggerConfig";
import Middleware from "../../lib/utils/Middleware";
import handlePopup from "../../lib/popup/handlePopup";
import SessionStorage from "../../lib/LocalCaching/SessionStorage";
import formSubmissionHelper from "../../lib/FormSubmission/formSubmissionHelper";
import InvalidDataModalMsg, { InvalidDataError } from "../UploadPage/InvalidDataModalMsg";
import displayInfoModal from "../../lib/StateSetters/displayInfoModal";
import Select, { SingleValue } from "react-select";
import SelectStyle from "../../lib/ReactSelectTheme/reactSelectStyle";

const distrCodeOpts = [
  {
    label: "1 - Normal distributions",
    value: "1",
  },
  {
    label: "2 - Excess contributions",
    value: "2",
  },
  {
    label: "3 - Disability",
    value: "3",
  },
  {
    label: "4 - Death distribution other than code 6",
    value: "4",
  },
  {
    label: "5 - Prohibited transaction",
    value: "5",
  },
  {
    label: "6 - Death distribution after year of death to a nonspouse beneficiary",
    value: "6",
  },
];

export default function Form1099SA({
  formData,
  setFormData,
  formType,
  taxYear,
  isEditable,
  isCorrection,
  historyId,
}: any) {
  const style = useSelector((state: any) => state.themeChange);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const correctionSubmissionType = useSelector((state: any) => state.correctionSubmissionType);

  const [amount, setAmount] = useState({
    amount1: "",
    amount2: "",
    amount4: "",
  }); // for displaying on the input field

  const [miscFields, setMiscFields] = useState({
    distribution_code: "",
    medicare_advantage_msa_indicator: false,
    hsa_indicator: false,
    archer_msa_indicator: false,
  });

  const { recTinUnknown } = useRecTinUnknown();

  useEffect(() => {
    // Set the formatted amount values
    setAmount({
      amount1: formData.payment_amount1
        ? handleAmountFormat((formData.payment_amount1 / 100).toString())
        : "",
      amount2: formData.payment_amount2
        ? handleAmountFormat((formData.payment_amount2 / 100).toString())
        : "",
      amount4: formData.payment_amount4
        ? handleAmountFormat((formData.payment_amount4 / 100).toString())
        : "",
    });

    // Set the misc field values
    setMiscFields({
      distribution_code: formData?.distribution_code ?? "",
      medicare_advantage_msa_indicator: formData?.medicare_advantage_msa_indicator || false,
      hsa_indicator: formData?.hsa_indicator || false,
      archer_msa_indicator: formData?.archer_msa_indicator || false,
    });

    // Reset
    return () => {
      setAmount({
        amount1: "",
        amount2: "",
        amount4: "",
      });

      setMiscFields({
        distribution_code: "",
        medicare_advantage_msa_indicator: false,
        hsa_indicator: false,
        archer_msa_indicator: false,
      });
    };
  }, [
    formData.payment_amount1,
    formData.payment_amount2,
    formData.payment_amount4,
    formData?.distribution_code,
    formData?.medicare_advantage_msa_indicator,
    formData?.hsa_indicator,
    formData?.archer_msa_indicator,
  ]);

  // Handle amount input change
  const handleChange = (e: any) => {
    const { value, id } = e.target;
    const formattedAmount = handleAmountFormat(value);
    switch (id) {
      case AMOUNT_1:
        isValidAmount(formattedAmount) &&
          setAmount({
            ...amount,
            amount1: formattedAmount,
          });
        break;

      case AMOUNT_2:
        isValidAmount(formattedAmount) &&
          setAmount({
            ...amount,
            amount2: formattedAmount,
          });
        break;

      case AMOUNT_4:
        isValidAmount(formattedAmount) &&
          setAmount({
            ...amount,
            amount4: formattedAmount,
          });
        break;

      default:
        break;
    }
  };

  const handleMiscFieldChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { id } = e.currentTarget;

    switch (id) {
      case MA_MSA_INDICATOR:
        setMiscFields({
          ...miscFields,
          hsa_indicator: false,
          archer_msa_indicator: false,
          medicare_advantage_msa_indicator: !miscFields.medicare_advantage_msa_indicator, // check and uncheck
        });
        break;

      case ARCHER_MSA_INDICATOR:
        setMiscFields({
          ...miscFields,
          hsa_indicator: false,
          medicare_advantage_msa_indicator: false,
          archer_msa_indicator: !miscFields.archer_msa_indicator, // check and uncheck
        });
        break;

      case HSA_INDICATOR:
        setMiscFields({
          ...miscFields,
          medicare_advantage_msa_indicator: false,
          archer_msa_indicator: false,
          hsa_indicator: !miscFields.hsa_indicator, // check and uncheck
        });
        break;

      default:
        break;
    }
  };

  // Handle distribution code change
  const handleDistrCodeChange = (option: SingleValue<{ label: string; value: string }>) => {
    if (!option) return;

    devLogInstance.log({ option });
    setMiscFields({
      ...miscFields,
      distribution_code: option.value,
    });
  };

  // Handle form submit
  const handleFormSubmit = async (e: any) => {
    e.preventDefault();
    const { id: submitType } = e.target;

    let data = {
      ...formData,
      payer_country: !formData.payer_is_foreign_address
        ? DEFAULT_NON_FOREIGN_COUNTRY
        : formData.payer_country,
      recipient_country: !formData.recipient_is_foreign_address
        ? DEFAULT_NON_FOREIGN_COUNTRY
        : formData.recipient_country,
      form_type: formType,
      payment_amount1: amount.amount1 ? parseFloat(amount.amount1.replace(/,/gi, "")) : 0,
      payment_amount2: amount.amount2 ? parseFloat(amount.amount2.replace(/,/gi, "")) : 0,
      payment_amount4: amount.amount4 ? parseFloat(amount.amount4.replace(/,/gi, "")) : 0,

      // Misc fields
      ...miscFields,
    };
    devLogInstance.log({ data_before_submitting: data });

    // Correction filing middleware
    Middleware.fileCorrection(
      {
        isCorrection: isCorrection,
        correctionSubmissionType: correctionSubmissionType,
        correctionCode1Details: data,
        correctionCode2Details: data,
        formType: data.form_type,
      },
      (err, correctionCode) => {
        if (err) {
          console.error(err);
          handlePopup(err, ERROR_POPUP, dispatch);
          return;
        }

        if (correctionCode) {
          data = {
            ...data,
            file_submission_type: correctionSubmissionType,
            correction_type: correctionCode,
            payer_is_new_or_updated: false,
            history_id: historyId,
          };
          devLogInstance.log("Data before sumbitting correction");
          devLogInstance.log(data);
        }

        devLogInstance.log("Removing cached intial data...");
        SessionStorage.removeCacheItem(CORRECTION_INITIAL);
        devLogInstance.log("Cache removed!");

        formSubmissionHelper(
          data,
          [data.payment_amount1, data.payment_amount2, data.payment_amount4],
          0,
          [data.payment_amount1, data.payment_amount2, data.payment_amount4],
          setFormData,
          setAmount,
          dispatch,
          submitType,
          navigate,
          PAGE_E_FILE,
          recTinUnknown
        )
          .then((res) => {
            if (res && submitType === SUBMIT_ADD_ANOTHER) {
              // Clear the local state values
              setMiscFields({
                distribution_code: "",
                medicare_advantage_msa_indicator: false,
                hsa_indicator: false,
                archer_msa_indicator: false,
              });
            }
          })
          .catch((err: InvalidDataError[]) => {
            // Error will be handled by the handler itself
            // Only error related to invalid data will be handled here
            // Display the SNS modal
            err.length >= 0 &&
              displayInfoModal(
                true,
                <InvalidDataModalMsg errors={err} />,
                "Invalid Data",
                dispatch
              );
          });
      }
    );
  };

  return (
    <>
      <form className="form-main-container">
        <div
          className="form-wrapper"
          style={{
            border: `2px solid ${style.eFormBorderColor}`,
          }}
        >
          <div className="form-details-amount-wrapper">
            <div className="left-portion-details">
              <PayerDetails
                globalFormData={formData}
                setGlobalFormData={setFormData}
                isCorrection={isCorrection}
              />
              <RecipientDetails
                globalFormData={formData}
                setGlobalFormData={setFormData}
                formType={formType}
                isCorrection={isCorrection}
              />
              <RecAccount
                globalFormData={formData}
                setGlobalFormData={setFormData}
                secondTinNoticeRequired={false}
              />
            </div>
            <div
              className="right-portion-details"
              style={{
                overflow: "hidden",
                borderBottom: `1px solid ${style.eFormBorderColor}`,
              }}
            >
              <div className="top-section">
                <div>
                  <div
                    className="amount-container blank h-full"
                    style={{
                      background: "#F5F5F5",
                      // width: "18rem",
                      width: "9rem",
                      borderTop: `1px solid ${style.eFormBorderColor}`,
                    }}
                  />
                </div>
                <FormDetail
                  formType={formType}
                  taxYear={taxYear}
                  formName={SA_1099}
                  newVersion={true}
                />
              </div>
              <div
                className="payment-amounts-container"
                style={{
                  borderRight: `1px solid ${style.eFormBorderColor}`,
                  borderBottom: `1px solid ${style.eFormBorderColor}`,
                }}
              >
                <AmountContainer
                  class_="misc-info" // Only one class can be passed
                  style={{
                    borderRight: `1px solid ${style.eFormBorderColor}`,
                  }}
                >
                  <AmountHeading heading={"Gross distribution"} amountNum="1" />
                  <Amount
                    type="text"
                    id={AMOUNT_1}
                    placeHolder="0.00"
                    class_="payer-details-input" // Only one class can be passed
                    value={amount.amount1}
                    onChangeHandler={handleChange}
                  />
                </AmountContainer>
                <AmountContainer
                  class_="misc-info" // Only one class can be passed
                >
                  <AmountHeading heading={"Earnings on excess cont."} amountNum="2" />
                  <Amount
                    type="text"
                    id={AMOUNT_2}
                    placeHolder="0.00"
                    class_="payer-details-input" // Only one class can be passed
                    value={amount.amount2}
                    onChangeHandler={handleChange}
                  />
                </AmountContainer>
              </div>
              <div
                className="payment-amounts-container"
                style={{
                  borderRight: `1px solid ${style.eFormBorderColor}`,
                  borderBottom: `1px solid ${style.eFormBorderColor}`,
                }}
              >
                <AmountContainer
                  class_="misc-info" // Only one class can be passed
                  style={{
                    borderRight: `1px solid ${style.eFormBorderColor}`,
                  }}
                >
                  <AmountHeading heading={"Distribution code"} amountNum="3" />
                  <Select
                    theme={new SelectStyle(style).customTheme}
                    styles={new SelectStyle(style).customStyle}
                    id="state"
                    options={distrCodeOpts}
                    onChange={handleDistrCodeChange}
                    isSearchable={false}
                    value={distrCodeOpts.find(
                      (option) => option.value === miscFields.distribution_code
                    )}
                  />
                </AmountContainer>
                <AmountContainer
                  class_="misc-info" // Only one class can be passed
                >
                  <AmountHeading heading={"FMV on date of death"} amountNum="4" />
                  <Amount
                    type="text"
                    id={AMOUNT_4}
                    placeHolder="0.00"
                    class_="payer-details-input" // Only one class can be passed
                    value={amount.amount4}
                    onChangeHandler={handleChange}
                  />
                </AmountContainer>
              </div>
              <div
                className="payment-amounts-container h-[10rem]"
                style={{
                  borderRight: `1px solid ${style.eFormBorderColor}`,
                  borderBottom: `1px solid ${style.eFormBorderColor}`,
                }}
              >
                <AmountContainer
                  class_="misc-info" // Only one class can be passed
                  style={{
                    flexDirection: "row",
                    justifyContent: "start",
                    borderRight: `1px solid ${style.eFormBorderColor}`,
                  }}
                >
                  <AmountHeading heading={""} amountNum="5" />
                  <div className="flex flex-col gap-4 w-full">
                    <div className="flex items-center justify-between">
                      <p>HSA</p>
                      <input
                        id={HSA_INDICATOR}
                        style={{ padding: "0.5rem 1rem" }}
                        type="checkbox"
                        checked={miscFields.hsa_indicator}
                        onChange={handleMiscFieldChange}
                      />
                    </div>
                    <div className="flex items-center justify-between">
                      <p>Archer MSA</p>
                      <input
                        id={ARCHER_MSA_INDICATOR}
                        style={{ padding: "0.5rem 1rem" }}
                        type="checkbox"
                        checked={miscFields.archer_msa_indicator}
                        onChange={handleMiscFieldChange}
                      />
                    </div>
                    <div className="flex items-center justify-between">
                      <p>MA MSA</p>
                      <input
                        id={MA_MSA_INDICATOR}
                        style={{ padding: "0.5rem 1rem" }}
                        type="checkbox"
                        checked={miscFields.medicare_advantage_msa_indicator}
                        onChange={handleMiscFieldChange}
                      />
                    </div>
                  </div>
                </AmountContainer>
                <div
                  className="amount-container blank h-full"
                  style={{
                    background: "#F5F5F5",
                    width: "100%",
                  }}
                />
              </div>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  borderRight: `1px solid ${style.eFormBorderColor}`,
                }}
              />
            </div>
          </div>
        </div>
      </form>
      {!isEditable ? (
        <SubmitButton handleSubmit={handleFormSubmit} isCorrection={isCorrection} />
      ) : (
        <UpdateButton handleUpdate={handleFormSubmit} />
      )}
    </>
  );
}
